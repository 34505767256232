<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        When preparing a solution of a solid material, you have to determine the mass required of
        the solid based on the intended volume in order to achieve a specific molarity. Please
        answer each of the following questions regarding the preparation of a solution of sodium
        sulfate,
        <chemical-latex content="Na2SO4:" />
      </p>

      <p class="mb-2">
        a) What is the molar mass of
        <chemical-latex content="Na2SO4?" />
      </p>

      <calculation-input
        v-model="inputs.molarMass"
        class="mb-4"
        prepend-text="$\text{Molar mass:}$"
        append-text="$\text{g/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) If you prepare a sodium sulfate solution by dissolving
        <number-value :value="mass" unit="\text{g}" />
        in enough water to form a
        <number-value :value="volume" unit="\text{mL}" />
        solution, determine the amount of sodium sulfate, in mol, that are in the solution?
      </p>

      <calculation-input
        v-model="inputs.mol"
        class="mb-4"
        prepend-text="$\ce{Na2SO4:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) Determine the concentration of the sodium sulfate solution described in part b).
      </p>

      <calculation-input
        v-model="inputs.molarity"
        prepend-text="$\ce{[Na2SO4]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question387',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molarMass: null,
        mol: null,
        molarity: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
  },
};
</script>
